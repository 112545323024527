import React, { useState, useEffect, useCallback } from "react";
import "./App.css";
import Navbar from "./component/navbar/Navbar";
import HeroSection from "./component/herosection/HeroSection";
import About from "./component/about/About";
import Education from "./component/education/Education";
import Skills from "./component/skills/Skills";
import Contact from "./component/contact/Contact1";
import Footer from "./component/footer/Footer";

function App() {
	const [toggleMenu, setToggleMenu] = useState(false);

	const handleScroll = useCallback(() => {
		const sections = document.querySelectorAll("section");
		const navLinks = document.querySelectorAll("header nav a");
		const navbar = document.querySelector(".navbar");
		const header = document.querySelector("header");
		const footer = document.querySelector("footer");

		sections.forEach((sec) => {
			let top = window.scrollY;
			let offset = sec.offsetTop - 100;
			let height = sec.offsetHeight;
			let id = sec.getAttribute("id");

			if (top >= offset && top < offset + height) {
				navLinks.forEach((links) => {
					links.classList.remove("active");
					document
						.querySelector(`header nav a[href*=${id}]`)
						.classList.add("active");
				});
				sec.classList.add("show-animate");
			} else {
				sec.classList.remove("show-animate");
			}
		});

		header.classList.toggle("sticky", window.scrollY > 100);
		navbar.classList.remove("active");
		setToggleMenu(true);

		const scrolled = window.scrollY + window.innerHeight;
		const totalHeight = document.documentElement.scrollHeight;
		const isBottom = scrolled >= totalHeight - 5;
		footer.classList.toggle("show-animate", isBottom);
	}, []);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [handleScroll]);

	return (
		<div className="body w-full h-full">
			<Navbar toggle={toggleMenu} setToggle={setToggleMenu} />
			<HeroSection />
			<About />
			<Education />
			<Skills />
			<Contact />
			<Footer />
		</div>
	);
}

export default App;
