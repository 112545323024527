import React, { useEffect } from "react";
import "./Navbar.css";
import { RiMenuLine } from "react-icons/ri";
import { CgClose } from "react-icons/cg";

const Navbar = ({ toggle, setToggle }) => {
	let navbar = document.querySelector(".navbar");

	const handleClick = () => {
		setToggle(!toggle);
		navbar?.classList?.toggle("active");
	};

	useEffect(() => {
		setToggle(true);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<header className="header">
			<a href="#home" className="logo">
				Imran. <span className="animate1" style={{ "--i": "1" }}></span>
			</a>

			<div id="menu-icon" className="" onClick={handleClick}>
				{toggle ? <RiMenuLine /> : <CgClose />}
				<span className="animate1" style={{ "--i": "2" }}></span>
			</div>
			<nav className="navbar">
				<a href="#home" className="active">
					Home
				</a>
				<a href="#about">About</a>
				<a href="#education">Education</a>
				<a href="#skills">Skills</a>
				<a href="#contact">Contact</a>

				<span className="active-nav"></span>
				<span className="animate1" style={{ "--i": "2" }}></span>
			</nav>
		</header>
	);
};

export default Navbar;
