import React from "react";
import { FaCalendarAlt } from "react-icons/fa";
import "./Education.css";

const Education = () => {
	return (
		<section className="education" id="education">
			<h2 className="heading">
				My <span>Journey</span>
				<span className="animate4 scroll" style={{ "--i": "1" }}></span>
			</h2>
			<div className="education-row">
				<div className="education-column">
					<h3 className="title">
						Education
						<span className="animate4 scroll" style={{ "--i": "2" }}></span>
					</h3>
					<div className="education-box">
						<div className="education-content">
							<div className="content">
								<div className="year flex gap-4 justify-start items-center">
									<FaCalendarAlt /> <span>2002-2006</span>
								</div>
								<h3>B.Tech in Computer Science and Engineering</h3>
								<p>
									I hold a B.Tech in Computer Science, a degree that provided a
									deep dive into algorithms, system architecture, programming,
									and database management. This comprehensive education has
									equipped me with a strong foundation in problem-solving and
									technical skills essential for innovative solutions in the
									tech industry.
								</p>
							</div>
						</div>
						<div className="education-content">
							<div className="content">
								<div className="year flex gap-4 justify-start items-center">
									<FaCalendarAlt /> <span>2006-2007</span>
								</div>
								<h3> Diploma in Advance Computing</h3>
								<p>
									I am a graduate of C-DAC Pune's Diploma in Advanced Computing,
									specializing in programming, web development, and emerging
									tech trends. This intensive training has honed my skills in
									software and web development, preparing me for a dynamic
									career in IT and tech solutions.
								</p>
							</div>
						</div>
						{/* <div className="education-content">
							<div className="content">
								<div className="year flex gap-4 justify-start items-center">
									<FaCalendarAlt /> <span>2006-2007</span>
								</div>
								<h3> Diploma in Advance Computing</h3>
								<p>
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt
									eius aliquid porro? Error modi accusantium amet distinctio?
									Placeat, excepturi a.
								</p>
							</div>
						</div> */}
						<span className="animate4 scroll" style={{ "--i": "3" }}></span>
					</div>
				</div>
				<div className="education-column">
					<h3 className="title">
						Experience
						<span className="animate4 scroll" style={{ "--i": "5" }}></span>
					</h3>
					<div className="education-box">
						<div className="education-content">
							<div className="content">
								<div className="year flex gap-4 justify-start items-center ">
									<FaCalendarAlt /> <span>2007-2010</span>
								</div>
								<h3>Junior Sybase Dba - Mahindra Satyam</h3>
								<p>
									I served as a Junior Sybase DBA at Mahindra Satyam for three
									years, where I specialized in database management and
									optimization. My role involved ensuring database integrity,
									performance tuning, and implementing robust data solutions,
									contributing to the company's technological efficiency and
									reliability.
								</p>
							</div>
						</div>
						<div className="education-content">
							<div className="content">
								<div className="year flex gap-4 justify-start items-center">
									<FaCalendarAlt /> <span>2010-2015</span>
								</div>
								<h3> Senior Sybase Dba - H R Technologies</h3>
								<p>
									With five years at H R Technologies as a Sybase DBA, I've
									mastered database administration, performance tuning, and
									security. My experience ranges from routine maintenance to
									complex problem-solving, significantly enhancing database
									performance and reliability for critical business operations.
								</p>
							</div>
						</div>
						<div className="education-content">
							<div className="content">
								<div className="year flex gap-4 justify-start items-center ">
									<FaCalendarAlt /> <span>2015-2021</span>
								</div>
								<h3> Web Developer - H R Technologies</h3>
								<p>
									After specializing in Sybase DBA for five years, I
									transitioned to web development in 2015, focusing on React.
									Until 2021, I developed dynamic, user-centric interfaces and
									single-page applications, leveraging React's powerful library
									to deliver responsive and efficient web solutions.
								</p>
							</div>
						</div>
						<div className="education-content">
							<div className="content">
								<div className="year flex gap-4 justify-start items-center ">
									<FaCalendarAlt /> <span>2021- till yet</span>
								</div>
								<h3> freelance Web Developer</h3>
								<p>
									Since 2021, I've thrived as a freelance Full Stack Developer,
									crafting a wide array of web solutions like e-shopping
									platforms, job portals, and personal websites. My work
									emphasizes custom, scalable solutions that enhance user
									engagement and drive business success, leveraging the latest
									in web technologies.
								</p>
							</div>
						</div>
						<span className="animate4 scroll" style={{ "--i": "6" }}></span>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Education;
