import React from "react";
import aboutImage from "../../images/aboutImage.png";
import "./About.css";

const About = () => {
	return (
		<section className="about" id="about">
			<h2 className="heading">
				About <span>Me</span>
				<span className="animate3 scroll" style={{ "--i": "1" }}></span>
			</h2>
			<div className="about-img">
				<img src={aboutImage} alt="" />
				<span className="circle-spin"></span>
				<span className="animate3 scroll" style={{ "--i": "2" }}></span>
			</div>
			<div className="about-content">
				<h3>
					Full Stack Developer!
					<span className="animate3 scroll" style={{ "--i": "3" }}></span>
				</h3>

				<p>
					As a versatile full-stack MERN developer with 8 years of experience, I
					specialize in creating customized websites, including e-commerce,
					corporate platforms, job portals, and personal portfolios. My
					proficiency in NextJs, React, Express, MongoDB, Firebase, and Node.js
					allows me to deliver robust, scalable solutions tailored to meet your
					unique business requirements. As a freelancer with a deep love for
					nature and agriculture, I am committed to leveraging technology to
					promote sustainable practices.
					<span className="animate3 scroll" style={{ "--i": "4" }}></span>
				</p>
				<p>
					I understand the importance of staying at the forefront of technology
					and continuously update my skills to ensure I can offer the most
					current and effective solutions. My approach combines a keen eye for
					design with technical expertise, ensuring that every project is not
					only functional but also visually appealing and user-friendly. Whether
					you're a startup looking to make your mark or an established business
					seeking to upgrade your online presence, I am here to help you
					navigate the digital landscape with confidence.
					<span className="animate3 scroll" style={{ "--i": "4" }}></span>
				</p>
				<p>
					My dedication goes beyond just delivering a product; I strive to
					understand your vision and goals, ensuring that the final website not
					only meets but exceeds your expectations. Let's collaborate to create
					a dynamic online presence that drives growth and reflects the quality
					and passion you bring to your field.
					<span className="animate3 scroll" style={{ "--i": "4" }}></span>
				</p>
				<div className="btn-box btns">
					<a href="#contact" className="btn">
						Contact Me
					</a>
					<span className="animate3 scroll" style={{ "--i": "5" }}></span>
				</div>
			</div>
		</section>
	);
};

export default About;
