import React from "react";
import { FaArrowUp } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer-text">
				<p>Copyright &copy; 2023 by Mohd Imran Khan | All Rights Reserved.</p>
				<span className="animate7 scroll" style={{ "--i": "1" }}></span>
			</div>
			<div className="footer-icon-top">
				<a href="#home">
					<FaArrowUp className="footer-image" />
				</a>
				<span className="animate7 scroll" style={{ "--i": "3" }}></span>
			</div>
		</footer>
	);
};

export default Footer;
