import React, { useRef } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const Contact = () => {
	const form = useRef();

	// Define your validation schema using Yup
	const validationSchema = Yup.object({
		full_name: Yup.string()
			.max(50, "Must be 50 characters or less")
			.required("Required"),
		user_email: Yup.string()
			.matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email must be valid")
			.required("Required"),
		user_mobile: Yup.string()
			.matches(
				/^\+?\d{1,3}\d{10}$/,
				"Must be a valid mobile number with country code"
			)
			.required("Required"),
		user_subject: Yup.string()
			.max(300, "Must be 50 words or less")
			.required("Required"),
		user_message: Yup.string()
			.max(600, "Must be 100 words or less")
			.required("Required"),
	});

	// Initial values for your fields
	const initialValues = {
		full_name: "",
		user_email: "",
		user_mobile: "",
		user_subject: "",
		user_message: "",
	};

	const sendEmail = (values, { setSubmitting, resetForm }) => {
		// Use emailjs to send the form
		emailjs
			.sendForm(
				"service_1r56wgm",
				"template_czni81c",
				form.current,
				"NZjerxbxVatvEe9P9"
			)
			.then(
				(result) => {
					console.log(result.text);
					resetForm();
				},
				(error) => {
					console.log(error.text);
				}
			)
			.finally(() => setSubmitting(false));
	};

	return (
		<section className="contact" id="contact">
			<h2 className="heading">
				Contact <span>Me!</span>
				<span className="animate6 scroll" style={{ "--i": "1" }}></span>
			</h2>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={sendEmail}
			>
				{({ isSubmitting }) => (
					<Form ref={form}>
						<div className="input-box">
							<div className="input-field">
								<Field type="text" name="full_name" placeholder="Full Name" />
								<ErrorMessage
									name="full_name"
									component="div"
									className="text-red-500"
								/>
								<span className="focus"></span>
							</div>
							<div className="input-field">
								<Field
									type="email"
									name="user_email"
									placeholder="Email Address"
								/>
								<ErrorMessage
									name="user_email"
									component="div"
									className="text-red-500"
								/>
								<span className="focus"></span>
							</div>
							<span className="animate6 scroll" style={{ "--i": "3" }}></span>
						</div>
						<div className="input-box">
							<div className="input-field">
								<Field
									type="text"
									name="user_mobile"
									placeholder="Mobile Number with Country Code"
									maxLength="15"
								/>
								<ErrorMessage
									name="user_mobile"
									component="div"
									className="text-red-500"
								/>

								<span className="focus"></span>
							</div>

							<div className="input-field">
								<Field
									type="text"
									name="user_subject"
									placeholder="Email Subject"
								/>
								<ErrorMessage
									name="user_subject"
									component="div"
									className="text-red-500"
								/>
								<span className="focus"></span>
							</div>
							<span className="animate6 scroll" style={{ "--i": "5" }}></span>
						</div>
						<div>
							<div className="textarea-field flex flex-col" id="textareaF">
								<Field
									as="textarea"
									name="user_message"
									placeholder="Your Message"
									cols="30"
									rows="10"
								/>

								<span className="focus"></span>
								<span className="animate6 scroll" style={{ "--i": "7" }}></span>
							</div>
							<ErrorMessage
								name="user_message"
								component="div"
								className="text-red-500 mt-[-10px]"
							/>
						</div>
						<div className="btn-box btns mt-6">
							<button type="submit" className="btn" disabled={isSubmitting}>
								Submit
							</button>
							<span className="animate6 scroll" style={{ "--i": "9" }}></span>
						</div>
					</Form>
				)}
			</Formik>
		</section>
	);
};

export default Contact;
