import React from "react";
import "./Skills.css";

const Skills = () => {
	return (
		<section className="skills" id="skills">
			<h2 className="heading mt-5">
				My <span>Skills</span>
				<span className="animate5 scroll" style={{ "--i": "1" }}></span>
			</h2>
			<div className="skills-row">
				<div className="skills-column  ">
					<div className="flex justify-center">
						<h3 className="title ">
							Coding Skills
							<span className="animate5 scroll" style={{ "--i": "2" }}></span>
						</h3>
					</div>
					<div className="skills-box flex justify-center">
						{/* <div className="skills-content">
							<div className="progress" id="HTML">
								<h3>
									HTML <span>90%</span>
								</h3>
								<div className="bar">
									<span></span>
								</div>
							</div>
							<div className="progress" id="CSS">
								<h3>
									CSS <span>80%</span>
								</h3>
								<div className="bar">
									<span></span>
								</div>
							</div>
							<div className="progress" id="JavaScript">
								<h3>
									JavaScript <span>80%</span>
								</h3>
								<div className="bar">
									<span></span>
								</div>
							</div>
							<div className="progress" id="React">
								<h3>
									React <span>85%</span>
								</h3>
								<div className="bar">
									<span></span>
								</div>
							</div>
							<div className="progress" id="MongoDb">
								<h3>
									MongoDb <span>80%</span>
								</h3>
								<div className="bar">
									<span></span>
								</div>
							</div>
							<div className="progress" id="Firebase">
								<h3>
									Firebase <span>85%</span>
								</h3>
								<div className="bar">
									<span></span>
								</div>
							</div>
							<div className="progress" id="Express">
								<h3>
									Express <span>75%</span>
								</h3>
								<div className="bar">
									<span></span>
								</div>
							</div>
							<div className="progress" id="Node">
								<h3>
									Node.js <span>80%</span>
								</h3>
								<div className="bar">
									<span></span>
								</div>
							</div>
							<div className="progress" id="ReactNative">
								<h3>
									ReactNative <span>40%</span>
								</h3>
								<div className="bar">
									<span></span>
								</div>
							</div>
							<div className="progress" id="WordPress">
								<h3>
									WordPress <span>90%</span>
								</h3>
								<div className="bar">
									<span></span>
								</div>
							</div>
						</div> */}
						<div className="text-3xl">
							I am good and proficient in following technologies{" "}
							<ul className="list-disc list-inside mt-5 text-3xl space-y-3">
								<li>HTML</li>
								<li>CSS</li>
								<li>JavaScript</li>
								<li>React Js</li>
								<li>Next Js</li>
								<li>Node Js</li>
								<li>Express Js</li>
								<li>MongoDb</li>
								<li>Firebase</li>
								<li>Wordpress</li>
							</ul>
						</div>

						<span className="animate5 scroll" style={{ "--i": "3" }}></span>
					</div>
				</div>
				<div className="skills-column ">
					<div className="flex justify-center">
						<h3 className="title">
							Professional Skills
							<span className="animate5 scroll" style={{ "--i": "5" }}></span>
						</h3>
					</div>
					<div className="skills-box flex justify-center">
						{/* <div className="skills-content">
							<div className="progress" id="design">
								<h3>
									Web Design <span>65%</span>
								</h3>
								<div className="bar">
									<span></span>
								</div>
							</div>
							<div className="progress" id="development">
								<h3>
									Web Development <span>95%</span>
								</h3>
								<div className="bar">
									<span></span>
								</div>
							</div>
							<div className="progress" id="marketing">
								<h3>
									SEO Marketing <span>90%</span>
								</h3>
								<div className="bar">
									<span></span>
								</div>
							</div>
							<div className="progress" id="deployment">
								<h3>
									Web Deployment <span>90%</span>
								</h3>
								<div className="bar">
									<span></span>
								</div>
							</div>
							<div className="progress" id="support">
								<h3>
									Web Suppport <span>100%</span>
								</h3>
								<div className="bar">
									<span></span>
								</div>
							</div>
						</div> */}
						<div className="text-3xl">
							My professional expertise includes
							<ul className="list-disc list-inside mt-5 text-3xl space-y-4">
								<li>Web Designing</li>
								<li>Web Development</li>
								<li>Search Engine Optimisation</li>
								<li>Web Deployment</li>
								<li>Web Support</li>
							</ul>
						</div>
						<span className="animate5 scroll" style={{ "--i": "6" }}></span>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Skills;
