import React from "react";
import "./HeroSection.css";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

const HeroSection = () => {
	return (
		<section className="hero show-animate" id="home">
			<div className="home-content">
				<h1>
					Hi, I'm <span>Mohd Imran Khan</span>
					<span className="animate2" style={{ "--i": "2" }}></span>
				</h1>
				<div className="text-animate">
					<h3>Full Stack Developer</h3>
					<span className="animate2" style={{ "--i": "3" }}></span>
				</div>
				<p>
					Seasoned MERN full-stack developer, I specialize in custom, robust
					websites from e-commerce to corporate solutions. With a passion for
					emerging technology, nature and agriculture, I provide elegant,
					sustainable web development and maintenance. Your vision is my
					mission, delivered with precision and care.
					<span className="animate2" style={{ "--i": "4" }}></span>
				</p>
				<div className="btn-box">
					<a href="#contact" className="btn">
						Hire Me
					</a>
					<a href="#contact" className="btn">
						Let's Talk
					</a>
					<span className="animate2" style={{ "--i": "5" }}></span>
				</div>
			</div>
			<div className="home-sci">
				<a
					href="https://www.facebook.com/mohdimran.khan.1029"
					target="_blank"
					rel="noopener noreferrer"
				>
					<FaFacebookF />
				</a>
				<a
					href="https://twitter.com/KhanImran82"
					target="_blank"
					rel="noopener noreferrer"
				>
					<FaTwitter />
				</a>
				<a
					href="https://www.linkedin.com/in/mohd-imran-khan-1a4b6912b/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<FaLinkedinIn />
				</a>
				<span className="animate2" style={{ "--i": "6" }}></span>
			</div>
			<div className="hero-imgHover"></div>
			<span className="animate2 home-img" style={{ "--i": "7" }}></span>
		</section>
	);
};

export default HeroSection;
